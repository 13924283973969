define('ember-meta-meta/initializers/meta', ['exports'], function (exports) {
  exports['default'] = {
    name: 'meta',

    initialize: function initialize() {
      // Support Ember 2.x and 1.1.13
      var app = arguments.length > 1 ? arguments[1] : arguments[0];
      app.inject('route', 'meta', 'service:meta');
    }
  };
});