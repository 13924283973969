define('ember-meta-meta/instance-initializers/meta', ['exports'], function (exports) {
  exports['default'] = {
    name: 'meta',

    initialize: function initialize(appInstance) {
      var meta = lookup(appInstance, 'service:meta');

      meta.storeDefaultTitle();

      // Bind on didTransition
      lookup(appInstance, 'router:main').on('didTransition', function () {
        meta.insert();
      });
    }
  };

  function lookup(appInstance, name) {
    // Ember 2.x
    if (appInstance.lookup) {
      return appInstance.lookup(name);
    }

    // Ember 1.13
    return appInstance.container.lookup(name);
  }
});